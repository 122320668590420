export const imageTemplate = (url) => {
    return `<img style="display: block; margin: 0 auto; width: 98%;" src="${url}"></img><br>`
}
export function toFixed(number, len) {
    if (len > 20 || len < 0) {
        throw new RangeError('toFixed() digits argument must be between 0 and 20');
    }
    // .123转为0.123
    // var number = Number(this);
    if (isNaN(number) || number >= Math.pow(10, 21)) {
        return number.toString();
    }
    if (typeof (len) == 'undefined' || len == 0) {
        return (Math.round(number)).toString();
    }
    var result = number.toString(),
        numberArr = result.split('.');

    if (numberArr.length < 2) {
        //整数的情况
        return padNum(result);
    }
    var intNum = numberArr[0], //整数部分
        deciNum = numberArr[1],//小数部分
        lastNum = deciNum.substr(len, 1);//最后一个数字

    if (deciNum.length == len) {
        //需要截取的长度等于当前长度
        return result;
    }
    if (deciNum.length < len) {
        //需要截取的长度大于当前长度 1.3.toFixed(2)
        return padNum(result)
    }
    //需要截取的长度小于当前长度，需要判断最后一位数字
    result = intNum + '.' + deciNum.substr(0, len);
    if (parseInt(lastNum, 10) >= 5) {
        //最后一位数字大于5，要进位
        var times = Math.pow(10, len); //需要放大的倍数
        var changedInt = Number(result.replace('.', ''));//截取后转为整数
        changedInt++;//整数进位
        changedInt /= times;//整数转为小数，注：有可能还是整数
        result = padNum(changedInt + '');
    }
    return result;
    //对数字末尾加0
    function padNum(num) {
        var dotPos = num.indexOf('.');
        if (dotPos === -1) {
            //整数的情况
            num += '.';
            for (var i = 0; i < len; i++) {
                num += '0';
            }
            return num;
        } else {
            //小数的情况
            var need = len - (num.length - dotPos - 1);
            for (var j = 0; j < need; j++) {
                num += '0';
            }
            return num;
        }
    }
}

export function downloadFile(res, type){
    console.log('downloadFile', res.headers);
    let blob = new Blob(
        [res.data],
        {
            type: 'application/octet-stream'
        }
    );
    console.log('blob', blob);
    let name = res.headers && res.headers['content-disposition'] ? res.headers['content-disposition'].split('=')[1] : 'out.zip'
    let url = type ? URL.createObjectURL(blob, type) : URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('download', name);
    a.href = url;
    document.body.append(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export function completeUserLogoUrl(avatar){
    if (avatar){
        let url = avatar.replace(/^\./g, '');
        if (!(url.startsWith('/'))) url = '/' + url;
        url = window.location.origin + url;
        // console.log('completeUserLogoUrl', url);
        return url;
    }
}
export function relativeTime (oldTime){
    const t = new Date(oldTime)
    // Date.now()：现在的时间戳（毫秒）
    // t.getTime()：旧时间的时间戳（毫秒）
  
    const diff = Date.now() - t.getTime() // 相隔多少毫秒
    // Math.floor 向下取整： 1.7年 ---> 1年前
    const year = Math.floor(diff / (1000 * 3600 * 24 * 365))
    if (year>0) {
      return `${year}年前`
    }
    const month = Math.floor(diff / (1000 * 3600 * 24 * 30))
    if (month>0) {
      return `${month}月前`
    }
    const day = Math.floor(diff / (1000 * 3600 * 24))
    if (day>0) {
      return `${day}天前`
    }
    const hour = Math.floor(diff / (1000 * 3600))
    if (hour>0) {
      return `${hour}小时前`
    }
    const minute = Math.floor(diff / (1000 * 60))
    // console.log('minute',minute);
    if (minute>0) {
      return `${minute}分钟前`
    } else {
      return '刚刚'
    }
  }
  /**
 * @description 下载文件方法
 * @param {*} fileName 文件名 必须要有文件类型后缀
 * @param {*} file 后端返回的二进制文件流
 */
  let downloadFiles = async (fileName, file) => {
    
    let blob = new Blob([file]);
    let href = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    link.style.display = 'none';
    console.log('downloadFile', href);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
}
export{
    downloadFiles,
}